.grid {
    display: grid;
    grid-template-columns: 1fr 1fr;
}
.grid-three {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
}

.card .grid img {
    transform: scale(0.9);
    transition: transform 0.15s;
    box-shadow: 0px 0px 77px -14px rgba(0,0,0,0.33);
    -webkit-box-shadow: 0px 0px 77px -14px rgba(0,0,0,0.33);
    -moz-box-shadow: 0px 0px 77px -14px rgba(0,0,0,0.33);
}

.card .grid:hover img {
    transform: scale(1);
}

hr.small {
    border-color: transparent;
    border-bottom: 1px solid black;
    width: 50px;
    margin-right: auto;
    margin-left: 0;
    padding-top: 10px;
}
.img-section-fullwidth {
    width: 90%;
}
p.text-grid-left {
    padding-right: 30px;
}
.card .tag:hover {
    color: unset;
    border-color: unset;
}

@media screen and (max-width: 768px) {
    .card-image {
        grid-row-end: 1;
    }
    .grid {
        grid-template-columns: 1fr;
        grid-template-rows: 1fr;
    }
    .img-section-fullwidth {
        width: 100%;
    }
    p.text-grid-left {
        padding-right: unset;
    }
}