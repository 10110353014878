.grid.pricing {
    column-gap: 15px;
    row-gap: 15px;
}

.pricing-option {
    border: 1px solid grey;
    border-radius: 5px;
    padding: 50px;
}
.pricing-option.primary {
    border-color: #00EDBF;
}
.pricing-option p {
    padding: 0 !important;
    margin: 0;
}
.pricing-option .title {
    font-family: 'Nanum Myeongjo';
    font-weight: 700;
    margin-bottom: 15px;
    font-size: 32px;
}
.pricing-option ul {
    padding: 0 30px !important;
    list-style-type: none;
}
.pricing-option li {
    font-size: 16px;
}
li {
    font-family: "Haas Light";
    font-size: 25px;
    line-height: 41.5px;
	letter-spacing: 1px;
}
.pricing-option i {
    margin-bottom: 10px;
}

.btn {
    border-radius: 5px;
    background: grey;
    color: white;
    font-family: 'Haas Regular';
    display: block;
    padding: 15px 100px;
    transition: 0.15s;
}
button.btn {
    border: none;
    text-align: center;
    margin: auto;
    font-size: 16px;
}
.btn:hover {
    background: rgb(83, 83, 83);
}
.btn-primary {
    background: #00EDBF;
    color: black;
}
.btn-primary:hover {
    background: #00bb96;
}
.btn-secondary {
    border: 1px solid grey;
    background: white;
    color: grey;
}
.btn-secondary:hover {
    color: white;
}

@media screen and (max-width: 512px) {
    li {
        font-size: 22px;
    }
}

@media screen and (max-width: 400px) {
    .pricing-option {
        padding: 30px;
    }
}