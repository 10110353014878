.page-title-card {
    background: white;
    color: black;
    padding: 50px 100px;
    margin-top: -200px;
}
.page-title-card h1 {
    margin: 0;
}
#content-header {
    text-align: center;
    /* For longer titles, align: left */
}

p.content {
    font-size: 25px;
    font-family: "Haas Light";
    line-height: 41.5px;
    padding: 0 100px;
    letter-spacing: 1px;
}

@media screen and (max-width: 768px) {
    .page-title-card {
        padding: 50px;
    }
    p.content {
        padding: 0 50px;
    }
    #content-header {
        text-align: center;
    }
}

@media screen and (max-width: 512px) {
    .page-title-card {
        padding: 30px;
        margin-top: 0;
    }
    p.content {
        font-size: 22px;
        padding: 0 20px;
    }
}