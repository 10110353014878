#trusted-clients {
    background: rgb(240, 240, 240);
}
#trusted-clients p {
    letter-spacing: 3px;
}

#trusted-clients.resume-page {
    margin-top: 100px;
}

.logo-grid {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr 1fr;
    padding: 50px 0;
}
.logo-grid > div {
    margin: auto;
}
.logo-grid img.FunBoy {
    width: 150px;
}
.logo-grid img {
    width: 100px;
}

@media screen and (max-width: 900px) {
    .logo-grid {
        grid-template-columns: 1fr 1fr;
    }
    .logo-grid > div {
        padding-bottom: 50px;
    }
}

@media screen and (max-width: 512px) {
    .logo-grid {
        grid-template-columns: 1fr;
    }
}