.grid.repos {
    column-gap: 15px;
    margin-bottom: 115px;
    row-gap: 15px;
}
.repo {
    border: 1px solid grey;
    border-radius: 5px;
    padding: 30px;
    transition: border-color 0.15s;
}
button.repo {
    background: unset;
    color: unset;
    font-size: unset;
    font-family: unset;
}

.repo.java:hover {
    border-color: #b07219;
}
.repo.java i.languageCircle {
    color: #b07219;
}
.repo.php:hover {
    border-color: #4F5D95;
}
.repo.php i.languageCircle {
    color: #4F5D95;
}
.repo.react:hover {
    border-color: #61DBFB;
}
.repo.react i.languageCircle {
    color: #61DBFB;
}
.repo.shopify:hover {
    border-color: #95BF47;
}
.repo.shopify i.languageCircle {
    color: #95BF47;
}
.repo.python:hover {
    border-color: #3878AE;
}
.repo.python i.languageCircle {
    color: #3878AE;
}
.repo.swift:hover {
    border-color: #FA553B;
}
.repo.swift i.languageCircle {
    color: #FA553B;
}

.repo p {
    padding: 0 !important;
    margin: 0;
    text-align: center;
}
.repo p.content i {
    vertical-align: text-top;
}
.repo i {
    vertical-align: middle;
}
.text-muted {
    color: grey;
}
.repo > .content > i {
    display: none;
    transition: 0.15s;
    transform: translateX(-15px);
}
.repo:hover > .content > i {
    display: inline-block;
    color: grey;
    animation: content_reveal 0.8s ease;
    transform: translateX(0);
}