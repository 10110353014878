html {
    -ms-touch-action: manipulation;
        touch-action: manipulation;
}

.topnav {
    overflow: hidden;
    padding: 0 100px;
    position: fixed;
    top: 0;
    width: 100%;
    background: rgba(255, 255, 255, 0.8);
    -webkit-backdrop-filter: blur(13px);
    backdrop-filter: blur(13px);
    z-index: 999;
    border-bottom: 1px solid rgba(250, 250, 250, 0.33);
}
.topnav a {
    float: left;
    display: block;
    color: #f2f2f2;
    text-align: center;
    padding: 30px 16px;
    text-decoration: none;
    font-size: 14px;
    letter-spacing: 3px;
    text-transform: uppercase;
    color: black;
    font-family: "Haas Light";
    transition: color 0.15s;
}
.topnav a:hover {
    color: rgba(0, 0, 0, 0.66);
}
.topnav .active {
    border-bottom: 1px solid black;
}
.topnav .icon {
    display: none;
}
.topnav .icon {
    display: none;
}

@media screen and (max-width: 768px) {
    .topnav {
        padding: 0;
    }
    .topnav a:not(:first-child) {
        display: none;
    }
    .topnav button.icon {
        /* float: right; */
        display: block;
        padding: 24px 16px;
        position: absolute;
        top: 0;
        right: 0;
        background: none;
        border: none;
        color: black;
    }
    .topnav .active {
        border-bottom: none;
    }
    .topnav .active span {
        border-bottom: 1px solid black;
        padding: 0 1px 7px 6px;
    }
    .topnav.responsive a.icon {
        position: absolute;
        right: 0;
        top: 0;
    }
    .topnav.responsive a {
        float: none;
        display: block;
        text-align: center;
    }
    .topnav.responsive a.brand {
        text-align: left;
    }
    .topnav {
        background: rgba(255, 255, 255, 0.8);
        border-bottom-color: rgb(230, 230, 230);
    }
}