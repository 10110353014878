.tags-list {
    width: 50%;
    margin: 0 auto;
}

span.tag {
    padding: 5px 9px;
    border: 1px solid #7d7d7d;
    border-radius: 5px;
    line-height: 15px;
    display: inline-block;
    margin: 4px;
    transition: 0.15s;
    text-transform: uppercase;
    font-size: 12px;
    color: #7d7d7d;
}

span.tag:hover {
    border-color: #29ACC5;
    color: #29ACC5;
}

hr.thin {
    margin: 100px auto;
    border-color: transparent;
    border-top-color: rgba(0, 0, 0, 0.33);
    max-width: 200px;
}

@media screen and (max-width: 768px) {
    .tags-list {
        width: 80%;
    }
}