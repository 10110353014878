body#search {
    background: black;
}
body#search .topnav {
    background: rgba(0,0,0,0.5);
    border-color: black;
}
body#search .topnav a:first-of-type {
    color: white;
}
body#search .topnav a {
    color: rgb(170, 170, 170);
}
body#search .topnav a:hover {
    color: #c2a26a;
}
body#search input::placeholder {
    font-family: "Font Awesome 5 Pro";
    font-size: 16px;
    position: fixed;
    top: 29px;
}
footer.smallFooter {
    display: block;
    background: black;
    color: white;
    padding: 15px 0;
}
footer.smallFooter .container {
    width: 90%;
}
footer.smallFooter .brand {
    letter-spacing: 3px;
}
footer.smallFooter .text {
    opacity: 0.6;
}
footer.smallFooter .text.icon {
    margin-right: 10px;
}
footer.smallFooter a {
    transition: opacity 0.15s;
}
footer.smallFooter a:hover {
    opacity: 1;
}
footer.smallFooter div:nth-of-type(2) {
    margin: auto;
}
footer.smallFooter div:nth-of-type(3) {
    text-align: right;
}


@media screen and (max-width: 768px) {
    footer.smallFooter .grid-three {
        text-align: center;
    }
    footer.smallFooter .grid-three div {
        margin-bottom: 30px;
    }
    .grid-three {
        grid-template-columns: 1fr;
    }
    .desktop-only-inline {
        display: none;
    }
    a.text.link {
        padding-left: 20px;
    }
}