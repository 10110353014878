:root {
    --contact-gradient: linear-gradient(rgba(255, 255, 255, 1), rgba(255, 255, 255, 0) 50%);
}
#contact {
    background: var(--contact-gradient), url("./portfolio.png");
    background-size: cover;
    background-position: center;
    padding: 100px 0;
}
.email {
    font-size: 45px;
    border: 1px solid black;
    font-family: 'Nanum Myeongjo';
    padding: 30px;
    -webkit-backdrop-filter: blur(15px);
    backdrop-filter: blur(15px);
    transform: scale(0.9);
    transition: all 0.15s;
}
.email:hover {
    transform: scale(1);
    background: white;
}

.desktop-only-inline {
    display: inline-block;
}


@media screen and (max-width: 512px) {
    .email {
        font-size: 35px;
    }
}