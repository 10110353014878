:root {
    --primary: #ad6cb0;
    --primary-dark: #8d4d90;
}

body {
    margin: 0;
    font-family: "Haas Regular";
}

a {
    color: unset;
    text-decoration: none;
}

body, html {
    animation: content_reveal 0.8s ease;
}
html {
    scroll-behavior: smooth;
}

p {
    line-height: 30px;
}

section {
    padding: 100px 0;
}

::-webkit-scrollbar {
    width: 10px;
}
::-webkit-scrollbar-track {
    background: rgba(230, 230, 230, 1);
}
::-webkit-scrollbar-thumb {
    background: rgba(200, 200, 200, 0.75);
}
::-webkit-scrollbar-thumb:hover {
    background: rgba(180, 180, 180, 1);
}

.container {
    width: 80%;
    margin: 0 auto;
}

.text-center {
    text-align: center;
}

.banner {
    padding: 390px 0;
}
.banner.projects {
    background: url("./pages/components/common/1-full.png");
    background-size: cover;
    background-position: center;
}
.banner.resume {
    background: url("./pages/components/common/2-full.png");
    background-size: cover;
    background-position: center;
}
.banner.about {
    background: url("./pages/components/common/3-full.png");
    background-size: cover;
    background-position: center;
}
.banner.services {
    background: url("./pages/components/common/4-full.png");
    background-size: cover;
    background-position: center;
}
.banner.thankyou {
    background: url("./pages/components/common/5-full.png");
    background-size: cover;
    background-position: top;
}
.banner.nopage {
    background: url("./pages/components/common/6-full.png");
    background-size: cover;
    background-position: top;
}
.banner.search {
    background: url("./pages/components/common/7-full.png");
    background-size: cover;
    background-position: top;
    padding: 380px 0;
}


@media screen and (min-width: 1700px) {
    .container {
        width: 60%;
    }
}

@media screen and (max-width: 768px) {
    .container {
        width: 90%;
    }
    .banner {
        padding: 340px 0;
    }
}

@media screen and (max-width: 512px) {
    .banner {
        padding: 275px 0;
    }
}

@media (prefers-color-scheme: dark) {
    :root {
        --jumbotron-gradient: linear-gradient(rgba(17, 17, 17, 0), rgba(17, 17, 17, 1));
        --contact-gradient: linear-gradient(rgba(17, 17, 17, 1), rgba(17, 17, 17, 0) 50%);
        --contact-form-gradient: linear-gradient(rgba(17, 17, 17, 1), rgba(17, 17, 17, 0) 20%, rgba(23, 23, 23, 0) 80%, rgba(23, 23, 23, 1));
        --primary-text-color: #ededed;
    }
    body, .page-title-card {
        background: #111111;
        color: var(--primary-text-color);
    }
    .topnav a, .topnav button.icon {
        color: var(--primary-text-color);
    }
    .topnav {
        background: rgb(34 34 34 / 80%);
        border-bottom: 1px solid rgb(80 80 80 / 33%);
    }
    #trusted-clients {
        background: #171717;
        color: var(--primary-text-color);
    }
    #trusted-clients .logo-grid img {
        filter: invert(1);
    }
    .email:hover {
        background: #252525a3;
    }
    a.link.secondary {
        color: #cccccc;
    }
    .topnav a:hover {
        color: #ccc;
    }
    .pricing-option {
        border: 1px solid #282828;
    }
    .btn-secondary, input, #contact-form .email, textarea, a.link.secondary {
        background: #252525;
        color: #adadad;
    }
    #contactform {
        padding-bottom: 0;
    }
}

@keyframes content_reveal {
    from {
        opacity: 0;
    }
    to {
        opacity: 1;
    }
}