a.link {
    color: black;
    text-decoration: none;
    background-color: #00EDBF;
    padding: 10px 20px;
    border-radius: 5px;
    transition: 0.15s;
    margin-right: 5px;
}
a.link:hover {
    background-color: #00bb96;
}
a.link.secondary {
    border: 1px solid black;
    color: black;
    background: white;
}
a.link.secondary:hover {
    background: black;
    color: white;
}
a.text.link {
    background: none;
    display: block;
    color: white;
    padding-left: 0;
}
a.text.link.active {
    opacity: 1;
}
.section-link {
    font-family: 'Nanum Myeongjo';
    font-size: 45px;
    font-weight: 800;
}

@media screen and (max-width: 512px) {
    .section-link {
        font-size: 35px;
    }
}