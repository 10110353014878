:root {
    --contact-form-gradient: linear-gradient(rgba(255, 255, 255, 1), rgba(255, 255, 255, 0) 20%, rgba(255, 255, 255, 0) 80%, rgba(255, 255, 255, 1));
}
.btn-gold {
    font-size: 14px !important;
    text-transform: uppercase;
    letter-spacing: 3px;
    background: #c2a26a !important;
    cursor: pointer;
}
.btn-gold:hover {
    background: #927a4f  !important;
}

#contact-form {
    background: var(--contact-form-gradient), url("./portfolio.png");
    background-position: center;
    padding: 150px 0;
    transform: 0.15s;
}
input[type=text], input, textarea {
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    border-radius: 0;
}
input, #contact-form .email, textarea {
    font-size: 30px;
    border: 1px solid black;
    font-family: 'Nanum Myeongjo';
    padding: 20px;
    -webkit-backdrop-filter: blur(15px);
    backdrop-filter: blur(15px);
    transition: all 0.15s;
    width: 100%;
    box-sizing: border-box;
    margin-bottom: 15px;
}
.search input {
    -webkit-backdrop-filter: blur(5px);
    backdrop-filter: blur(5px);
    color: white;
    background: rgba(0, 0, 0, 0.5);
}
textarea {
    resize: none;
    height: 169px;
}
input:focus, textarea:focus {
    outline: 2px solid #c2a26a !important;
}
#contact-form .email {
    transform: scale(1);
}
#contact-form .grid {
    column-gap: 15px;
    row-gap: 15px;
}
#contactform {
    padding: 50px 0;
}
.bigspacer {
    padding: 75px;
}

@media screen and (max-width: 768px) {
    .btn-gold {
        width: 100%;
    }
}