:root {
    --jumbotron-background: url('./marble.jpg');
    --jumbotron-gradient: linear-gradient(rgba(255, 255, 255, 0), rgba(255, 255, 255, 1));
}
@media (prefers-color-scheme: dark) {
    :root {
      --jumbotron-background: url('./marble-dark.jpg');
    }
}
.marble-bg {
    background: var(--jumbotron-gradient), var(--jumbotron-background);
    background-size: cover;
    background-position: center;
}

.main {
    padding-top: 90px;
}

.main-section {
    padding: 100px 0;
    position: relative;
    z-index: 1;
}
.main-section::before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-image: url("../common/logo.png");
    background-size: contain; 
    background-position: right;
    background-repeat: no-repeat;
    opacity: 0.8;
    z-index: -1; 
    pointer-events: none; 
  }

.main-title {
    font-size: 35px;
}
.main-title {
    font-size: 60px;
    font-family: 'Nanum Myeongjo';
    font-weight: 800;
    word-break: break-word;
    width: 70%;
}

.main a.link:not(.secondary) {
    border: 1px solid #00EDBF;
}

@media screen and (max-width: 768px) {
    .main-section::before {
        opacity: 0.5;
    }
}

@media screen and (max-width: 512px) {
    .main-title {
        font-size: 35px;
    }
}