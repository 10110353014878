footer {
    background: black;
    color: white;
    padding: 100px 0;
}
footer .brand {
    letter-spacing: 3px;
}
footer .text {
    opacity: 0.6;
}
footer .text.icon {
    margin-right: 10px;
}
footer a {
    transition: opacity 0.15s;
}
footer a:hover {
    opacity: 1;
}

@media screen and (max-width: 768px) {
    footer .grid-three {
        text-align: center;
    }
    footer .grid-three div {
        margin-bottom: 30px;
    }
    .grid-three {
        grid-template-columns: 1fr;
    }
    .desktop-only-inline {
        display: none;
    }
    a.text.link {
        padding-left: 20px;
    }
}